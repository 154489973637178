import './leaflet.css';

if (typeof window !== 'undefined') {
    var L = require('leaflet');
}


let initMap = function (zoom) {
    if (!L) return;

    let map = L.map('mdtmap', {scrollWheelZoom: zoom}).setView([25.758464, -80.255186], 11);

    L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox.streets',
        accessToken: 'pk.eyJ1Ijoiam1zZnJ1aXRpc29uIiwiYSI6ImNqNXZ1cmt3dDA1eHgzMmxlMmdmcWwxcW8ifQ.1HQR26PenLL2SKVEvb0rTw'
    }).addTo(map);

    L.polyline([[25.846146, -80.234006],[25.846216, -80.231849],[25.846291, -80.229129],[25.846331, -80.227336],[25.846424, -80.224682],[25.846476, -80.223133],[25.846529, -80.221481],[25.846602, -80.219035],[25.846676, -80.216277],[25.846775, -80.214297],[25.846858, -80.212239],[25.846910, -80.210973],[25.846948, -80.208569],[25.847092, -80.205145],[25.847160, -80.203189],[25.847213, -80.201483],[25.847246, -80.199923],[25.847375, -80.196125]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112', {interactive: true, sticky: true});

    L.polyline([[25.770545, -80.305414],[25.770446, -80.307976],[25.770389, -80.310995]], {color: 'red', weight: 5}).addTo(map).bindTooltip('7, 11, 73', {interactive: true, sticky: true});

    L.polyline([[25.847375, -80.196125],[25.847432, -80.194457],[25.847484, -80.192565],[25.847601, -80.189084],[25.847680, -80.186588]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112, 202', {interactive: true, sticky: true});

    L.polyline([[25.760381, -80.192881],[25.762664, -80.195261],[25.764016, -80.192560],[25.766888, -80.192121],[25.769165, -80.192248],[25.771051, -80.192558],[25.771865, -80.191377],[25.773099, -80.187323],[25.775726, -80.189849],[25.777584, -80.189915],[25.778926, -80.192085],[25.778815, -80.195341],[25.775864, -80.196093]], {color: 'red', weight: 5}).addTo(map).bindTooltip('MMO', {interactive: true, sticky: true});

    L.polyline([[25.854799, -80.122382],[25.844137, -80.121605],[25.837331, -80.121096],[25.822153, -80.122767],[25.812522, -80.124371],[25.805450, -80.126405],[25.800318, -80.127260]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120', {interactive: true, sticky: true});

    L.polyline([[25.783609, -80.206695],[25.784596, -80.206697],[25.786846, -80.206801],[25.788075, -80.206834],[25.789984, -80.206868],[25.791401, -80.206871],[25.792898, -80.206888],[25.794746, -80.206908],[25.797477, -80.207004],[25.798568, -80.207044],[25.800195, -80.207080],[25.801673, -80.207147],[25.803409, -80.207207],[25.805579, -80.207280],[25.806668, -80.207325],[25.808631, -80.207387],[25.809736, -80.207440],[25.816306, -80.207712],[25.817359, -80.207750],[25.818881, -80.207815],[25.820350, -80.207870],[25.822007, -80.207932],[25.823894, -80.208001],[25.825254, -80.208060],[25.826666, -80.208131],[25.829078, -80.208240],[25.830344, -80.208296],[25.831140, -80.207732]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.764765, -80.249412],[25.764887, -80.245373],[25.764951, -80.243327],[25.765006, -80.241227],[25.765071, -80.239374],[25.765147, -80.236564],[25.765221, -80.234603],[25.765275, -80.232474],[25.765317, -80.230937],[25.765390, -80.228274],[25.765435, -80.226370],[25.765492, -80.224329],[25.765546, -80.222259],[25.765634, -80.219299],[25.765683, -80.217675],[25.765745, -80.215553],[25.765786, -80.214104],[25.765867, -80.211226],[25.765866, -80.209071],[25.765982, -80.205446],[25.766063, -80.202570],[25.766185, -80.197878],[25.764317, -80.195318]], {color: 'red', weight: 5}).addTo(map).bindTooltip('8', {interactive: true, sticky: true});

    L.polyline([[25.772663, -80.230623],[25.772611, -80.233138],[25.772490, -80.236933],[25.772426, -80.238786],[25.772340, -80.241989],[25.772271, -80.244186],[25.772217, -80.246245],[25.772155, -80.248436],[25.772108, -80.249682],[25.772045, -80.252236],[25.771944, -80.254354],[25.771945, -80.255848],[25.771916, -80.257155],[25.771862, -80.259766],[25.771820, -80.261426],[25.771802, -80.263429],[25.771728, -80.265486],[25.771663, -80.267532],[25.771604, -80.269682],[25.771547, -80.271518],[25.771475, -80.273571],[25.771382, -80.275662],[25.771274, -80.278148],[25.771135, -80.281554],[25.771010, -80.284665],[25.770966, -80.286050],[25.770890, -80.287647],[25.770838, -80.290222],[25.770805, -80.291995],[25.770776, -80.293748],[25.770733, -80.295573],[25.770716, -80.297386]], {color: 'red', weight: 5}).addTo(map).bindTooltip('11', {interactive: true, sticky: true});

    L.polyline([[25.857337, -80.122478],[25.856111, -80.123540],[25.856101, -80.124674],[25.855953, -80.128373],[25.855884, -80.129425],[25.855091, -80.133133],[25.853396, -80.135854],[25.852433, -80.138075],[25.852331, -80.140351],[25.851351, -80.143367],[25.848348, -80.146939],[25.848563, -80.148253],[25.848927, -80.150716],[25.848817, -80.154526],[25.848667, -80.158332],[25.848509, -80.166322],[25.848209, -80.174642],[25.848149, -80.176729],[25.848078, -80.179297],[25.847978, -80.182546],[25.847941, -80.184132],[25.847852, -80.186931]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112', {interactive: true, sticky: true});

    L.polyline([[25.764317, -80.195318],[25.767300, -80.197748],[25.767229, -80.200589],[25.767193, -80.202259],[25.767084, -80.206010],[25.767026, -80.207281],[25.766978, -80.209332],[25.766894, -80.211041],[25.766821, -80.214266],[25.766718, -80.217273],[25.766609, -80.220946],[25.766599, -80.222969],[25.766517, -80.226361],[25.766463, -80.228726],[25.766404, -80.230989],[25.766286, -80.234617],[25.765642, -80.237841],[25.765246, -80.239216],[25.765174, -80.242131],[25.765095, -80.244296],[25.764999, -80.247395]], {color: 'red', weight: 5}).addTo(map).bindTooltip('8', {interactive: true, sticky: true});

    L.polyline([[25.792318, -80.134523],[25.792246, -80.136877],[25.792195, -80.138739],[25.792169, -80.139864],[25.790109, -80.141334],[25.787509, -80.141241],[25.785355, -80.141178],[25.783637, -80.141132],[25.780715, -80.141032],[25.779591, -80.141013],[25.778298, -80.140972]], {color: 'red', weight: 5}).addTo(map).bindTooltip('113, 119', {interactive: true, sticky: true});

    L.polyline([[25.915561, -80.211948],[25.913326, -80.211835],[25.910370, -80.211810],[25.908463, -80.211780],[25.907180, -80.211778],[25.905165, -80.211746],[25.903802, -80.211687],[25.901512, -80.211572],[25.899327, -80.211463],[25.898428, -80.211411],[25.896566, -80.211320],[25.894806, -80.211240],[25.892563, -80.211138],[25.890780, -80.211050],[25.888931, -80.210963],[25.886926, -80.210872],[25.884426, -80.210744],[25.881660, -80.210610],[25.880235, -80.210545],[25.878078, -80.210446],[25.875840, -80.210339],[25.873036, -80.210205],[25.870498, -80.210120],[25.868496, -80.210007],[25.867530, -80.209972],[25.865697, -80.209919],[25.864317, -80.209878],[25.861491, -80.209790],[25.858799, -80.209688],[25.857313, -80.209635],[25.855316, -80.209559],[25.853758, -80.209498],[25.851885, -80.209457],[25.850505, -80.209383],[25.849194, -80.209343],[25.846572, -80.209217],[25.844666, -80.209143],[25.843171, -80.209086],[25.841731, -80.209026],[25.839826, -80.208951],[25.837583, -80.208842]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.831140, -80.207732],[25.830220, -80.208496],[25.829026, -80.208437],[25.826131, -80.208297],[25.824433, -80.208228],[25.822967, -80.208175],[25.820999, -80.208091],[25.819529, -80.208037],[25.818428, -80.207990],[25.816229, -80.207897],[25.809401, -80.207594],[25.807914, -80.207543],[25.806205, -80.207474],[25.803783, -80.207391],[25.803514, -80.207478],[25.802384, -80.207339],[25.800979, -80.207295],[25.799621, -80.207252],[25.798589, -80.207218],[25.796813, -80.207150],[25.795025, -80.207101],[25.792958, -80.207053],[25.791279, -80.207035],[25.789586, -80.207006],[25.787471, -80.206985],[25.786418, -80.206950],[25.784487, -80.206888],[25.782211, -80.206816],[25.781101, -80.206781],[25.779682, -80.206735],[25.777740, -80.204366],[25.776196, -80.202559],[25.775339, -80.201636],[25.774065, -80.200725],[25.772330, -80.197814],[25.773176, -80.196472],[25.773224, -80.193917],[25.774031, -80.191867]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.844956, -80.208943],[25.847388, -80.209045],[25.849214, -80.209096],[25.851002, -80.209171],[25.854032, -80.209271],[25.856165, -80.209350],[25.858344, -80.209429],[25.860756, -80.209519],[25.862604, -80.209572],[25.864435, -80.209643],[25.865947, -80.209698],[25.868080, -80.209755],[25.869550, -80.209796],[25.871767, -80.209871],[25.873079, -80.209965],[25.874601, -80.210026],[25.876832, -80.210136],[25.878695, -80.210224],[25.880405, -80.210297],[25.882455, -80.210394],[25.884068, -80.210466],[25.885140, -80.210514],[25.887698, -80.210638],[25.889006, -80.210689],[25.891404, -80.210817],[25.893148, -80.210889],[25.894918, -80.210978],[25.896648, -80.211055],[25.898758, -80.211157],[25.900329, -80.211241],[25.902484, -80.211341],[25.904173, -80.211425],[25.905869, -80.211483],[25.907597, -80.211503],[25.909541, -80.211522],[25.911202, -80.211531],[25.913353, -80.211571]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.764805, -80.254353],[25.764717, -80.257488],[25.764634, -80.259917],[25.764535, -80.263015],[25.764468, -80.265092],[25.764391, -80.267396],[25.764321, -80.269533],[25.764231, -80.271929],[25.764163, -80.273916],[25.764076, -80.276422],[25.764030, -80.278030],[25.763951, -80.279902],[25.763875, -80.282044],[25.763802, -80.284061],[25.763746, -80.285610],[25.763671, -80.287442],[25.763553, -80.290351],[25.763544, -80.291092],[25.763456, -80.293872],[25.763379, -80.296323],[25.763325, -80.298865],[25.763272, -80.300320],[25.763225, -80.302096],[25.763188, -80.303696],[25.763110, -80.306393],[25.763047, -80.307603],[25.762973, -80.309673],[25.762910, -80.311436],[25.762854, -80.313087],[25.762911, -80.314805],[25.762772, -80.316672],[25.762349, -80.328745],[25.762125, -80.337355],[25.762005, -80.345204],[25.761893, -80.349671],[25.761821, -80.353206],[25.761686, -80.361298],[25.759294, -80.368584]], {color: 'red', weight: 5}).addTo(map).bindTooltip('8', {interactive: true, sticky: true});

    L.polyline([[25.778364, -80.140737],[25.779305, -80.141883],[25.780786, -80.142050],[25.784105, -80.142162],[25.786487, -80.142238],[25.788024, -80.142286],[25.790155, -80.142355],[25.790973, -80.141082],[25.794544, -80.141210],[25.794986, -80.142626],[25.795459, -80.143498]], {color: 'red', weight: 5}).addTo(map).bindTooltip('123', {interactive: true, sticky: true});

    L.polyline([[25.774767, -80.134264],[25.773447, -80.134562],[25.771324, -80.134981],[25.769840, -80.135277],[25.768357, -80.135867],[25.770918, -80.138279],[25.773306, -80.139941],[25.775439, -80.140658]], {color: 'red', weight: 5}).addTo(map).bindTooltip('123', {interactive: true, sticky: true});

    L.polyline([[25.791925, -80.144125],[25.791342, -80.147616],[25.791556, -80.144535]], {color: 'red', weight: 5}).addTo(map).bindTooltip('123, 123', {interactive: true, sticky: true});

    L.polyline([[25.935863, -80.121484],[25.937453, -80.121388],[25.939186, -80.121295],[25.942574, -80.121087]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119', {interactive: true, sticky: true});

    L.polyline([[25.854799, -80.122382],[25.852292, -80.122316],[25.848779, -80.121637],[25.844137, -80.121605],[25.841147, -80.121566],[25.839482, -80.121206],[25.837331, -80.121096],[25.836003, -80.120969],[25.833502, -80.121392],[25.831875, -80.122014],[25.830234, -80.122310],[25.828023, -80.122049],[25.826957, -80.121961],[25.825259, -80.122221],[25.822153, -80.122767],[25.819191, -80.123112],[25.817402, -80.123444],[25.816541, -80.123610],[25.815023, -80.123732]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112, 119', {interactive: true, sticky: true});

    L.polyline([[25.770400, -80.304414],[25.770433, -80.303011],[25.770467, -80.301507],[25.770495, -80.299395],[25.770539, -80.297278],[25.770571, -80.295407],[25.770613, -80.293145],[25.770634, -80.291969],[25.770657, -80.290183],[25.770714, -80.287697],[25.770793, -80.285717],[25.770899, -80.283378],[25.770968, -80.281254],[25.771070, -80.278869],[25.771150, -80.276841],[25.771254, -80.274202],[25.771368, -80.271308],[25.771423, -80.269864],[25.771456, -80.268370],[25.771528, -80.265815],[25.771615, -80.262900],[25.771643, -80.261333],[25.771685, -80.259240],[25.771712, -80.257176],[25.771732, -80.255048],[25.771796, -80.253578],[25.771839, -80.251634],[25.771887, -80.250066],[25.771972, -80.247031],[25.772024, -80.245542],[25.772075, -80.243923],[25.772136, -80.241991],[25.772222, -80.239399],[25.772314, -80.237205],[25.772346, -80.235794],[25.771640, -80.233135],[25.771363, -80.228992]], {color: 'red', weight: 5}).addTo(map).bindTooltip('11', {interactive: true, sticky: true});

    L.polyline([[25.883766, -80.123515],[25.881420, -80.123238],[25.879598, -80.123060],[25.877775, -80.122852],[25.876352, -80.122672],[25.873343, -80.122366],[25.872162, -80.122301],[25.870404, -80.122554],[25.867817, -80.122354],[25.865854, -80.122321],[25.864260, -80.122302],[25.861964, -80.122242],[25.860411, -80.122331],[25.859285, -80.122270],[25.857337, -80.122478]], {color: 'red', weight: 5}).addTo(map).bindTooltip('108, 119', {interactive: true, sticky: true});

    L.polyline([[25.775864, -80.196093],[25.778815, -80.195341],[25.778926, -80.192085],[25.777584, -80.189915],[25.775726, -80.189849],[25.773099, -80.187323]], {color: 'red', weight: 5}).addTo(map).bindTooltip('MMI', {interactive: true, sticky: true});

    L.polyline([[25.847680, -80.186588],[25.847765, -80.184185],[25.847810, -80.182335],[25.847890, -80.179725],[25.847971, -80.176559],[25.848046, -80.174172],[25.848192, -80.166226],[25.848325, -80.158863],[25.848521, -80.154375],[25.848661, -80.150270],[25.848274, -80.148339],[25.848169, -80.146583],[25.851194, -80.141583],[25.851260, -80.139735],[25.851591, -80.136969],[25.852855, -80.134717],[25.854298, -80.132091],[25.854821, -80.129373],[25.855828, -80.127428],[25.855902, -80.124594],[25.854799, -80.122382]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112', {interactive: true, sticky: true});

    L.polyline([[25.857859, -80.120640],[25.862101, -80.120973],[25.870341, -80.121294],[25.876470, -80.121575],[25.883307, -80.122284],[25.889586, -80.123786],[25.894365, -80.124126],[25.908070, -80.123011]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120', {interactive: true, sticky: true});

    L.polyline([[25.770389, -80.310995],[25.770243, -80.313117],[25.770171, -80.314617],[25.770108, -80.316063],[25.770036, -80.317705]], {color: 'red', weight: 5}).addTo(map).bindTooltip('7, 11', {interactive: true, sticky: true});

    L.polyline([[25.930455, -80.121857],[25.932120, -80.121721],[25.933874, -80.121606],[25.935863, -80.121484]], {color: 'red', weight: 5}).addTo(map).bindTooltip('105, 119', {interactive: true, sticky: true});

    L.polyline([[25.447973, -80.478168],[25.448463, -80.479227],[25.462373, -80.482055],[25.466001, -80.479861],[25.471162, -80.475375]], {color: 'red', weight: 5}).addTo(map).bindTooltip('38', {interactive: true, sticky: true});

    L.polyline([[25.797346, -80.128328],[25.798690, -80.127782],[25.800321, -80.126881]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 113, 119', {interactive: true, sticky: true});

    L.polyline([[25.614382, -80.348269],[25.609509, -80.350552],[25.603559, -80.353292],[25.597941, -80.355939],[25.589356, -80.361326],[25.579509, -80.369431]], {color: 'red', weight: 5}).addTo(map).bindTooltip('31, 38', {interactive: true, sticky: true});

    L.polyline([[25.814338, -80.122729],[25.821625, -80.122441],[25.837468, -80.120715],[25.844212, -80.120597],[25.854134, -80.120339]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120', {interactive: true, sticky: true});

    L.polyline([[25.840403, -80.233212],[25.841622, -80.233252],[25.842643, -80.233293]], {color: 'red', weight: 5}).addTo(map).bindTooltip('12, 21, 22', {interactive: true, sticky: true});

    L.polyline([[25.800321, -80.126881],[25.802838, -80.125893],[25.804108, -80.125375]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 112, 113, 119, 150', {interactive: true, sticky: true});

    L.polyline([[25.773804, -80.208343],[25.773770, -80.209623],[25.773704, -80.211658],[25.773558, -80.215064],[25.773523, -80.216666],[25.773421, -80.219520],[25.773366, -80.221701]], {color: 'red', weight: 5}).addTo(map).bindTooltip('6, 11', {interactive: true, sticky: true});

    L.polyline([[25.771363, -80.228992],[25.771568, -80.225825],[25.771772, -80.224505],[25.772202, -80.222148],[25.772290, -80.219435],[25.772340, -80.217609],[25.772388, -80.216072],[25.772447, -80.214340],[25.772490, -80.212726],[25.772546, -80.211056],[25.772632, -80.209049]], {color: 'red', weight: 5}).addTo(map).bindTooltip('6, 11', {interactive: true, sticky: true});

    L.polyline([[25.763467, -80.288412],[25.763520, -80.286204],[25.763615, -80.283988],[25.763703, -80.281043],[25.763833, -80.278037],[25.763877, -80.275836],[25.763970, -80.274151],[25.764036, -80.272266],[25.764159, -80.269629],[25.764216, -80.267727],[25.764240, -80.266636],[25.764265, -80.265121],[25.764354, -80.262575],[25.764606, -80.254464]], {color: 'red', weight: 5}).addTo(map).bindTooltip('8', {interactive: true, sticky: true});

    L.polyline([[25.858517, -80.121430],[25.857337, -80.122478]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112', {interactive: true, sticky: true});

    L.polyline([[25.806827, -80.124101],[25.811226, -80.123192],[25.814338, -80.122729]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120, 150', {interactive: true, sticky: true});

    L.polyline([[25.814338, -80.122729],[25.815727, -80.122521],[25.818104, -80.122992],[25.820333, -80.122554],[25.821625, -80.122441],[25.824233, -80.122034],[25.825283, -80.121816],[25.826479, -80.121580],[25.828263, -80.121693],[25.829899, -80.121916],[25.831563, -80.121673],[25.832779, -80.121326],[25.834339, -80.120642],[25.835604, -80.120540],[25.837468, -80.120715],[25.839274, -80.120798],[25.841011, -80.121152],[25.844212, -80.120597],[25.846374, -80.120160],[25.847973, -80.120181],[25.850423, -80.120241],[25.854134, -80.120339]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112, 119', {interactive: true, sticky: true});

    L.polyline([[25.784744, -80.189124],[25.787478, -80.189022],[25.789278, -80.188188]], {color: 'red', weight: 5}).addTo(map).bindTooltip('3, 103, 119', {interactive: true, sticky: true});

    L.polyline([[25.927981, -80.122104],[25.930455, -80.121857]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119', {interactive: true, sticky: true});

    L.polyline([[25.774767, -80.134264],[25.774525, -80.139828],[25.784551, -80.174746],[25.789278, -80.188188],[25.782231, -80.190596],[25.777888, -80.190473],[25.774217, -80.195242]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120', {interactive: true, sticky: true});

    L.polyline([[25.792538, -80.131804],[25.794236, -80.131862],[25.797874, -80.131991],[25.798564, -80.128941],[25.796627, -80.128838],[25.795567, -80.132175],[25.794055, -80.132116]], {color: 'red', weight: 5}).addTo(map).bindTooltip('123, 123', {interactive: true, sticky: true});

    L.polyline([[25.948841, -80.120966],[25.946862, -80.121110],[25.945441, -80.121205],[25.944527, -80.121254],[25.943028, -80.121350]], {color: 'red', weight: 5}).addTo(map).bindTooltip('105, 119', {interactive: true, sticky: true});

    L.polyline([[25.857859, -80.120640],[25.861097, -80.120939],[25.862101, -80.120973],[25.864346, -80.121018],[25.866339, -80.121082],[25.868330, -80.121106],[25.870341, -80.121294],[25.871918, -80.121413],[25.873455, -80.121301],[25.876470, -80.121575],[25.879699, -80.121948],[25.881703, -80.122130],[25.883307, -80.122284],[25.885148, -80.122451],[25.886963, -80.122624],[25.889586, -80.123786],[25.892360, -80.123995],[25.894365, -80.124126],[25.896435, -80.124259],[25.903872, -80.123934],[25.908738, -80.122561],[25.913637, -80.122498],[25.916780, -80.122420],[25.921542, -80.122293],[25.923465, -80.122223],[25.925404, -80.122163],[25.927981, -80.122104]], {color: 'red', weight: 5}).addTo(map).bindTooltip('108, 119', {interactive: true, sticky: true});

    L.polyline([[25.942574, -80.121087],[25.943802, -80.120993],[25.945982, -80.120840],[25.947026, -80.120769],[25.948960, -80.120653]], {color: 'red', weight: 5}).addTo(map).bindTooltip('105, 119', {interactive: true, sticky: true});

    L.polyline([[25.772801, -80.225090],[25.772708, -80.228342],[25.772663, -80.230623]], {color: 'red', weight: 5}).addTo(map).bindTooltip('6, 11', {interactive: true, sticky: true});

    L.polyline([[25.789278, -80.188188],[25.786824, -80.189227],[25.784567, -80.189584]], {color: 'red', weight: 5}).addTo(map).bindTooltip('3, 103, 119', {interactive: true, sticky: true});

    L.polyline([[25.685035, -80.313665],[25.673257, -80.320919],[25.666947, -80.323836],[25.660795, -80.326757],[25.654534, -80.329625],[25.650763, -80.331374],[25.644544, -80.334261],[25.635668, -80.338390]], {color: 'red', weight: 5}).addTo(map).bindTooltip('31, 38, 52, 252', {interactive: true, sticky: true});

    L.polyline([[25.913353, -80.211571],[25.914717, -80.211623],[25.917266, -80.211749],[25.918769, -80.211822],[25.920796, -80.215067]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77, 105', {interactive: true, sticky: true});

    L.polyline([[25.941026, -80.204972],[25.942313, -80.205025],[25.944601, -80.205134],[25.946035, -80.205172],[25.947961, -80.205238],[25.950108, -80.205304],[25.952041, -80.205370],[25.953880, -80.205431],[25.957170, -80.206829],[25.956992, -80.209733],[25.956384, -80.213885]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.779676, -80.133152],[25.778590, -80.133384],[25.776671, -80.133839],[25.774767, -80.134264]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 123', {interactive: true, sticky: true});

    L.polyline([[25.772632, -80.209049],[25.772648, -80.207449],[25.772760, -80.204029],[25.772806, -80.202784],[25.773176, -80.196472]], {color: 'red', weight: 5}).addTo(map).bindTooltip('11', {interactive: true, sticky: true});

    L.polyline([[25.845364, -80.249120],[25.845785, -80.245441],[25.845839, -80.243491],[25.845923, -80.241040],[25.845995, -80.239088],[25.846047, -80.236627],[25.846146, -80.234006]], {color: 'red', weight: 5}).addTo(map).bindTooltip('12, 21, 112', {interactive: true, sticky: true});

    L.polyline([[25.774090, -80.323616],[25.773548, -80.324359],[25.771809, -80.324306],[25.769659, -80.323087],[25.769667, -80.321327],[25.769761, -80.318807],[25.769868, -80.316518],[25.769943, -80.314911],[25.770067, -80.311746]], {color: 'red', weight: 5}).addTo(map).bindTooltip('11', {interactive: true, sticky: true});

    L.polyline([[25.895075, -80.124471],[25.891993, -80.124282],[25.888051, -80.123949]], {color: 'red', weight: 5}).addTo(map).bindTooltip('108, 119', {interactive: true, sticky: true});

    L.polyline([[25.961128, -80.143242],[25.958462, -80.145053]], {color: 'red', weight: 5}).addTo(map).bindTooltip('9, 99, 105, 119', {interactive: true, sticky: true});

    L.polyline([[25.773741, -80.195936],[25.773310, -80.191424]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 119', {interactive: true, sticky: true});

    L.polyline([[25.921079, -80.213638],[25.937605, -80.204777]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.810584, -80.124565],[25.809182, -80.124541],[25.808178, -80.124614],[25.807055, -80.125509],[25.805450, -80.126405]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 112, 113, 119', {interactive: true, sticky: true});

    L.polyline([[25.943028, -80.121350],[25.941074, -80.121482],[25.939453, -80.121603],[25.937859, -80.121684],[25.935862, -80.121798]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119', {interactive: true, sticky: true});

    L.polyline([[25.793753, -80.129437],[25.795401, -80.129127],[25.797346, -80.128328]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 112, 113, 119', {interactive: true, sticky: true});

    L.polyline([[25.785022, -80.175926],[25.777484, -80.164429],[25.772447, -80.152636],[25.771767, -80.149068]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 113, 119', {interactive: true, sticky: true});

    L.polyline([[25.847546, -80.196256],[25.847462, -80.198666],[25.847389, -80.201285],[25.847340, -80.202801],[25.847305, -80.203831],[25.847257, -80.205530],[25.847142, -80.208851],[25.845909, -80.249716]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112', {interactive: true, sticky: true});

    L.polyline([[25.850908, -80.282368],[25.851130, -80.280399],[25.851217, -80.278738],[25.851351, -80.274804],[25.850077, -80.274608],[25.848862, -80.274546],[25.846745, -80.274429],[25.844576, -80.273107],[25.844684, -80.270408],[25.844734, -80.268387],[25.845146, -80.265715]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112', {interactive: true, sticky: true});

    L.polyline([[25.471691, -80.475151],[25.466286, -80.479859],[25.462365, -80.482271],[25.448281, -80.479365],[25.447707, -80.476900],[25.455471, -80.474990],[25.457782, -80.472076]], {color: 'red', weight: 5}).addTo(map).bindTooltip('38', {interactive: true, sticky: true});

    L.polyline([[25.958462, -80.145053],[25.948841, -80.120966],[25.945441, -80.121205],[25.941074, -80.121482],[25.937859, -80.121684],[25.932029, -80.122043],[25.926610, -80.122456],[25.921913, -80.122561],[25.903624, -80.124437]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120', {interactive: true, sticky: true});

    L.polyline([[25.576204, -80.372655],[25.566732, -80.372316],[25.565886, -80.382785],[25.562836, -80.385608],[25.551031, -80.397309],[25.539486, -80.408623]], {color: 'red', weight: 5}).addTo(map).bindTooltip('38', {interactive: true, sticky: true});

    L.polyline([[25.916592, -80.122700],[25.913376, -80.122822],[25.908968, -80.122864],[25.907447, -80.122869],[25.903624, -80.124437]], {color: 'red', weight: 5}).addTo(map).bindTooltip('108, 119', {interactive: true, sticky: true});

    L.polyline([[25.772112, -80.149097],[25.772784, -80.152420],[25.777932, -80.164420],[25.784551, -80.174746],[25.787143, -80.187685],[25.789278, -80.188188]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 113, 119', {interactive: true, sticky: true});

    L.polyline([[25.775864, -80.196093],[25.772024, -80.193508],[25.771051, -80.192558],[25.769165, -80.192248],[25.766888, -80.192121],[25.764016, -80.192560],[25.762664, -80.195261],[25.760381, -80.192881]], {color: 'red', weight: 5}).addTo(map).bindTooltip('MMO', {interactive: true, sticky: true});

    L.polyline([[25.822064, -80.240622],[25.832385, -80.241105],[25.845924, -80.248810],[25.845562, -80.259795],[25.841241, -80.279041],[25.839913, -80.301570],[25.843452, -80.322828]], {color: 'red', weight: 5}).addTo(map).bindTooltip('RAIL', {interactive: true, sticky: true});

    L.polyline([[25.883766, -80.123515],[25.876352, -80.122672],[25.870404, -80.122554],[25.861964, -80.122242],[25.857337, -80.122478]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120', {interactive: true, sticky: true});

    L.polyline([[25.764999, -80.247395],[25.764916, -80.249882],[25.764885, -80.251670],[25.764805, -80.254353]], {color: 'red', weight: 5}).addTo(map).bindTooltip('6, 8', {interactive: true, sticky: true});

    L.polyline([[25.774986, -80.133925],[25.779983, -80.132903],[25.784338, -80.131973],[25.790611, -80.131081]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120, 150', {interactive: true, sticky: true});

    L.polyline([[25.805450, -80.126405],[25.804258, -80.126848],[25.801938, -80.126336],[25.800318, -80.127260]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 112, 113, 119, 150', {interactive: true, sticky: true});

    L.polyline([[25.955068, -80.120552],[25.955254, -80.138746],[25.957683, -80.139250],[25.959509, -80.139332],[25.961729, -80.140647]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119', {interactive: true, sticky: true});

    L.polyline([[25.580496, -80.368244],[25.590300, -80.360120],[25.597981, -80.355730],[25.603412, -80.353159],[25.609625, -80.350281],[25.614439, -80.348050]], {color: 'red', weight: 5}).addTo(map).bindTooltip('31, 38', {interactive: true, sticky: true});

    L.polyline([[25.945640, -80.169522],[25.945015, -80.171265],[25.944867, -80.172975]], {color: 'red', weight: 5}).addTo(map).bindTooltip('9, 108, 183', {interactive: true, sticky: true});

    L.polyline([[25.797346, -80.128328],[25.800321, -80.126881]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112, 120, 150', {interactive: true, sticky: true});

    L.polyline([[25.960673, -80.139720],[25.956204, -80.139322],[25.954975, -80.138990],[25.953757, -80.136289]], {color: 'red', weight: 5}).addTo(map).bindTooltip('105, 105, 119', {interactive: true, sticky: true});

    L.polyline([[25.792503, -80.129817],[25.789840, -80.131957],[25.784379, -80.132192],[25.779676, -80.133152],[25.774767, -80.134264]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120, 150', {interactive: true, sticky: true});

    L.polyline([[25.845330, -80.259907],[25.845407, -80.257290],[25.845464, -80.255516],[25.845509, -80.253869],[25.845599, -80.251437],[25.845364, -80.249120]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112', {interactive: true, sticky: true});

    L.polyline([[25.769777, -80.332786],[25.769750, -80.336068]], {color: 'red', weight: 5}).addTo(map).bindTooltip('11', {interactive: true, sticky: true});

    L.polyline([[25.800318, -80.127260],[25.799212, -80.127776],[25.796627, -80.128838]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 112, 113, 119', {interactive: true, sticky: true});

    L.polyline([[25.506563, -80.440819],[25.491710, -80.454970],[25.477046, -80.469449],[25.471691, -80.475151]], {color: 'red', weight: 5}).addTo(map).bindTooltip('38', {interactive: true, sticky: true});

    L.polyline([[25.614439, -80.348050],[25.619854, -80.345535],[25.628291, -80.341621],[25.635664, -80.338205]], {color: 'red', weight: 5}).addTo(map).bindTooltip('31, 38', {interactive: true, sticky: true});

    L.polyline([[25.935862, -80.121798],[25.933377, -80.121945],[25.932029, -80.122043]], {color: 'red', weight: 5}).addTo(map).bindTooltip('105, 119', {interactive: true, sticky: true});

    L.polyline([[25.775105, -80.196794],[25.775458, -80.201585],[25.776284, -80.202444],[25.777807, -80.204125],[25.778393, -80.206093],[25.780357, -80.206582],[25.781743, -80.206626]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.954584, -80.120300],[25.955068, -80.120552]], {color: 'red', weight: 5}).addTo(map).bindTooltip('105, 105, 119, 119', {interactive: true, sticky: true});

    L.polyline([[25.955505, -80.213740],[25.956732, -80.212124],[25.956833, -80.209933],[25.956954, -80.206330],[25.953499, -80.205701],[25.949479, -80.205573],[25.947002, -80.205486],[25.944815, -80.205417],[25.941719, -80.205284],[25.940382, -80.205212],[25.938720, -80.205114],[25.937361, -80.205038],[25.920796, -80.215067]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.920796, -80.215067],[25.921079, -80.213638]], {color: 'red', weight: 5}).addTo(map).bindTooltip('22, 22, 77, 77, 105', {interactive: true, sticky: true});

    L.polyline([[25.471162, -80.475375],[25.477027, -80.469164],[25.491600, -80.454834],[25.506544, -80.440555]], {color: 'red', weight: 5}).addTo(map).bindTooltip('38', {interactive: true, sticky: true});

    L.polyline([[25.929227, -80.170762],[25.927264, -80.170201]], {color: 'red', weight: 5}).addTo(map).bindTooltip('9, 9, 10, 16, 19<br>22', {interactive: true, sticky: true});

    L.polyline([[25.798116, -80.258748],[25.795179, -80.278030]], {color: 'red', weight: 5}).addTo(map).bindTooltip('MIA', {interactive: true, sticky: true});

    L.polyline([[25.929023, -80.122424],[25.926610, -80.122456],[25.924047, -80.122502],[25.921913, -80.122561]], {color: 'red', weight: 5}).addTo(map).bindTooltip('108, 119', {interactive: true, sticky: true});

    L.polyline([[25.774031, -80.191867],[25.775207, -80.192327]], {color: 'red', weight: 5}).addTo(map).bindTooltip('11, 77', {interactive: true, sticky: true});

    L.polyline([[25.960876, -80.143117],[25.961319, -80.141399],[25.960673, -80.139720]], {color: 'red', weight: 5}).addTo(map).bindTooltip('105, 119', {interactive: true, sticky: true});

    L.polyline([[25.775439, -80.140658],[25.778364, -80.140737]], {color: 'red', weight: 5}).addTo(map).bindTooltip('113, 119, 123', {interactive: true, sticky: true});

    L.polyline([[25.522126, -80.425284],[25.539458, -80.408369]], {color: 'red', weight: 5}).addTo(map).bindTooltip('38', {interactive: true, sticky: true});

    L.polyline([[25.457782, -80.472076],[25.462510, -80.472122],[25.455393, -80.475300],[25.447974, -80.476876]], {color: 'red', weight: 5}).addTo(map).bindTooltip('38', {interactive: true, sticky: true});

    L.polyline([[25.926941, -80.176020],[25.928748, -80.176103]], {color: 'red', weight: 5}).addTo(map).bindTooltip('2, 3, 9, 10, 16<br>19, 22, 108, 108', {interactive: true, sticky: true});

    L.polyline([[25.782623, -80.132730],[25.781769, -80.132700],[25.779676, -80.133152]], {color: 'red', weight: 5}).addTo(map).bindTooltip('123', {interactive: true, sticky: true});

    L.polyline([[25.770036, -80.317705],[25.769919, -80.325363]], {color: 'red', weight: 5}).addTo(map).bindTooltip('11', {interactive: true, sticky: true});

    L.polyline([[25.804108, -80.125375],[25.805334, -80.124712],[25.806827, -80.124101],[25.807662, -80.123755],[25.809182, -80.123510],[25.811226, -80.123192],[25.812789, -80.122965]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 112, 113, 119', {interactive: true, sticky: true});

    L.polyline([[25.929144, -80.173780],[25.929227, -80.170762]], {color: 'red', weight: 5}).addTo(map).bindTooltip('3, 9, 9, 10, 16<br>19, 22, 75, 108, 108', {interactive: true, sticky: true});

    L.polyline([[25.791974, -80.132010],[25.789840, -80.131957],[25.787334, -80.131930],[25.785578, -80.132036],[25.784379, -80.132192],[25.782623, -80.132730]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 123', {interactive: true, sticky: true});

    L.polyline([[25.775832, -80.140924],[25.772112, -80.149097]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119', {interactive: true, sticky: true});

    L.polyline([[25.775162, -80.193939],[25.774217, -80.195242]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119', {interactive: true, sticky: true});

    L.polyline([[25.844508, -80.233653],[25.842597, -80.233601],[25.841379, -80.233537]], {color: 'red', weight: 5}).addTo(map).bindTooltip('12, 21, 22', {interactive: true, sticky: true});

    L.polyline([[25.775162, -80.193939],[25.775105, -80.196794]], {color: 'red', weight: 5}).addTo(map).bindTooltip('11, 77', {interactive: true, sticky: true});

    L.polyline([[25.840055, -80.208770],[25.842312, -80.208867],[25.843548, -80.208904],[25.844956, -80.208943]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77, 202', {interactive: true, sticky: true});

    L.polyline([[25.789142, -80.141073],[25.792000, -80.140291],[25.792069, -80.137457]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119', {interactive: true, sticky: true});

    L.polyline([[25.773099, -80.187323],[25.771865, -80.191377],[25.773391, -80.193582],[25.775864, -80.196093]], {color: 'red', weight: 5}).addTo(map).bindTooltip('MMI', {interactive: true, sticky: true});

    L.polyline([[25.921913, -80.122561],[25.916592, -80.122700]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119', {interactive: true, sticky: true});

    L.polyline([[25.928748, -80.176103],[25.929144, -80.173780]], {color: 'red', weight: 5}).addTo(map).bindTooltip('3, 9, 10, 16, 19<br>22, 108, 108', {interactive: true, sticky: true});

    L.polyline([[25.790611, -80.131081],[25.792114, -80.129688]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 112, 113, 119, 120<br>150', {interactive: true, sticky: true});

    L.polyline([[25.847852, -80.186931],[25.847775, -80.189212],[25.847704, -80.191125],[25.847645, -80.192995],[25.847546, -80.196256]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112, 202', {interactive: true, sticky: true});

    L.polyline([[25.635664, -80.338205],[25.644519, -80.334092],[25.651929, -80.330627],[25.655727, -80.328884],[25.660700, -80.326642],[25.667010, -80.323640],[25.673233, -80.320745],[25.685035, -80.313665]], {color: 'red', weight: 5}).addTo(map).bindTooltip('31, 38, 52', {interactive: true, sticky: true});

    L.polyline([[25.781743, -80.206626],[25.783609, -80.206695]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77, 211', {interactive: true, sticky: true});

    L.polyline([[25.773176, -80.196472],[25.774031, -80.191867]], {color: 'red', weight: 5}).addTo(map).bindTooltip('2, 11', {interactive: true, sticky: true});

    L.polyline([[25.770067, -80.311746],[25.770173, -80.309873],[25.770289, -80.307045],[25.770365, -80.305630],[25.770400, -80.304414]], {color: 'red', weight: 5}).addTo(map).bindTooltip('11, 73', {interactive: true, sticky: true});

    L.polyline([[25.770716, -80.297386],[25.770671, -80.299797],[25.770624, -80.302596],[25.770545, -80.305414]], {color: 'red', weight: 5}).addTo(map).bindTooltip('7, 11', {interactive: true, sticky: true});

    L.polyline([[25.778364, -80.140737],[25.779995, -80.140784],[25.780720, -80.140816],[25.781957, -80.140847],[25.784143, -80.140916],[25.785365, -80.140942],[25.787541, -80.141021],[25.789142, -80.141073]], {color: 'red', weight: 5}).addTo(map).bindTooltip('113, 119', {interactive: true, sticky: true});

    L.polyline([[25.932029, -80.122043],[25.929023, -80.122424]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119', {interactive: true, sticky: true});

    L.polyline([[25.777221, -80.189826],[25.775498, -80.190362]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 119', {interactive: true, sticky: true});

    L.polyline([[25.777782, -80.191912],[25.782754, -80.192169],[25.789278, -80.188188]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120', {interactive: true, sticky: true});

    L.polyline([[25.769393, -80.344847],[25.769321, -80.346823],[25.769237, -80.349202],[25.769173, -80.351045],[25.769088, -80.353738],[25.769025, -80.355899],[25.768949, -80.358336],[25.768894, -80.360734],[25.768795, -80.363884],[25.768703, -80.366252],[25.767969, -80.369078]], {color: 'red', weight: 5}).addTo(map).bindTooltip('11', {interactive: true, sticky: true});

    L.polyline([[25.506544, -80.440555],[25.513759, -80.433393],[25.522126, -80.425284]], {color: 'red', weight: 5}).addTo(map).bindTooltip('38', {interactive: true, sticky: true});

    L.polyline([[25.785022, -80.175926],[25.774170, -80.139731],[25.774986, -80.133925]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120', {interactive: true, sticky: true});

    L.polyline([[25.539458, -80.408369],[25.551497, -80.396609],[25.562253, -80.386005],[25.566039, -80.382386],[25.567193, -80.372110],[25.572993, -80.372176],[25.580496, -80.368244]], {color: 'red', weight: 5}).addTo(map).bindTooltip('38', {interactive: true, sticky: true});

    L.polyline([[25.961729, -80.140647],[25.961128, -80.143242]], {color: 'red', weight: 5}).addTo(map).bindTooltip('105, 119', {interactive: true, sticky: true});

    L.polyline([[25.791556, -80.144535],[25.794714, -80.136769],[25.792113, -80.135967]], {color: 'red', weight: 5}).addTo(map).bindTooltip('123', {interactive: true, sticky: true});

    L.polyline([[25.792354, -80.132987],[25.792318, -80.134523]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112, 113, 119, 123', {interactive: true, sticky: true});

    L.polyline([[25.839705, -80.301566],[25.685333, -80.313324]], {color: 'red', weight: 5}).addTo(map).bindTooltip('RAIL', {interactive: true, sticky: true});

    L.polyline([[25.773310, -80.191424],[25.773402, -80.188658],[25.774199, -80.187081],[25.776610, -80.187508],[25.777976, -80.187915],[25.781084, -80.189154],[25.782745, -80.189172],[25.784744, -80.189124]], {color: 'red', weight: 5}).addTo(map).bindTooltip('3, 93, 103, 119', {interactive: true, sticky: true});

    L.polyline([[25.837583, -80.208842],[25.835398, -80.208742],[25.833999, -80.208666]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.784567, -80.189584],[25.782699, -80.189654],[25.780236, -80.189276],[25.778866, -80.188853],[25.777441, -80.188440],[25.777221, -80.189826]], {color: 'red', weight: 5}).addTo(map).bindTooltip('3, 93, 103, 119', {interactive: true, sticky: true});

    L.polyline([[25.831140, -80.207732],[25.832554, -80.208397]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.958462, -80.145053],[25.960876, -80.143117]], {color: 'red', weight: 5}).addTo(map).bindTooltip('9, 99, 105, 119', {interactive: true, sticky: true});

    L.polyline([[25.521972, -80.425649],[25.514213, -80.433264],[25.506563, -80.440819]], {color: 'red', weight: 5}).addTo(map).bindTooltip('38', {interactive: true, sticky: true});

    L.polyline([[25.764606, -80.254464],[25.764686, -80.251617],[25.764765, -80.249412]], {color: 'red', weight: 5}).addTo(map).bindTooltip('6, 8', {interactive: true, sticky: true});

    L.polyline([[25.807620, -80.264650],[25.798116, -80.258748]], {color: 'red', weight: 5}).addTo(map).bindTooltip('37, 42, 110', {interactive: true, sticky: true});

    L.polyline([[25.579509, -80.369431],[25.576204, -80.372655]], {color: 'red', weight: 5}).addTo(map).bindTooltip('31, 38', {interactive: true, sticky: true});

    L.polyline([[25.756233, -80.368488],[25.752642, -80.368785]], {color: 'red', weight: 5}).addTo(map).bindTooltip('8', {interactive: true, sticky: true});

    L.polyline([[25.832614, -80.208598],[25.831140, -80.207732]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.539486, -80.408623],[25.521972, -80.425649]], {color: 'red', weight: 5}).addTo(map).bindTooltip('38', {interactive: true, sticky: true});

    L.polyline([[25.796627, -80.128838],[25.794102, -80.129569],[25.792503, -80.129817]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 112, 113, 119, 150', {interactive: true, sticky: true});

    L.polyline([[25.795179, -80.278030],[25.798116, -80.258748]], {color: 'red', weight: 5}).addTo(map).bindTooltip('MIA', {interactive: true, sticky: true});

    L.polyline([[25.953757, -80.136289],[25.954549, -80.133266]], {color: 'red', weight: 5}).addTo(map).bindTooltip('105, 105, 105, 119', {interactive: true, sticky: true});

    L.polyline([[25.812522, -80.124371],[25.810584, -80.124565]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 112, 113, 119, 150', {interactive: true, sticky: true});

    L.polyline([[25.773741, -80.195936],[25.774031, -80.191867]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120', {interactive: true, sticky: true});

    L.polyline([[25.775105, -80.196794],[25.773951, -80.204258],[25.773873, -80.205955],[25.773804, -80.208343]], {color: 'red', weight: 5}).addTo(map).bindTooltip('11', {interactive: true, sticky: true});

    L.polyline([[25.795459, -80.143498],[25.791925, -80.144125]], {color: 'red', weight: 5}).addTo(map).bindTooltip('123', {interactive: true, sticky: true});

    L.polyline([[25.946166, -80.163911],[25.946070, -80.165659]], {color: 'red', weight: 5}).addTo(map).bindTooltip('9, 108, 183', {interactive: true, sticky: true});

    L.polyline([[25.854134, -80.120339],[25.857859, -80.120640]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119, 120', {interactive: true, sticky: true});

    L.polyline([[25.948960, -80.120653],[25.954584, -80.120300]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119', {interactive: true, sticky: true});

    L.polyline([[25.954549, -80.133266],[25.954584, -80.120300]], {color: 'red', weight: 5}).addTo(map).bindTooltip('105, 119', {interactive: true, sticky: true});

    L.polyline([[25.792318, -80.134523],[25.792266, -80.136418]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112, 123', {interactive: true, sticky: true});

    L.polyline([[25.903624, -80.124437],[25.895075, -80.124471]], {color: 'red', weight: 5}).addTo(map).bindTooltip('108, 119, 120', {interactive: true, sticky: true});

    L.polyline([[25.792113, -80.135967],[25.792137, -80.134676]], {color: 'red', weight: 5}).addTo(map).bindTooltip('113, 119, 123', {interactive: true, sticky: true});

    L.polyline([[25.921079, -80.213638],[25.919036, -80.212099],[25.917063, -80.212015],[25.915561, -80.211948]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77, 105', {interactive: true, sticky: true});

    L.polyline([[25.854134, -80.120339],[25.858517, -80.121430]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112', {interactive: true, sticky: true});

    L.polyline([[25.794055, -80.132116],[25.791974, -80.132010]], {color: 'red', weight: 5}).addTo(map).bindTooltip('123', {interactive: true, sticky: true});

    L.polyline([[25.775498, -80.190362],[25.775207, -80.192327]], {color: 'red', weight: 5}).addTo(map).bindTooltip('9, 103, 119', {interactive: true, sticky: true});

    L.polyline([[25.792114, -80.129688],[25.797346, -80.128328]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120', {interactive: true, sticky: true});

    L.polyline([[25.925671, -80.173390],[25.926941, -80.176020]], {color: 'red', weight: 5}).addTo(map).bindTooltip('2, 3, 9, 16, 19<br>108, 108', {interactive: true, sticky: true});

    L.polyline([[25.792137, -80.134676],[25.790611, -80.131081]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112, 113, 119', {interactive: true, sticky: true});

    L.polyline([[25.775207, -80.192327],[25.775162, -80.193939]], {color: 'red', weight: 5}).addTo(map).bindTooltip('9, 11, 77, 103, 119', {interactive: true, sticky: true});

    L.polyline([[25.792137, -80.134676],[25.792538, -80.131804]], {color: 'red', weight: 5}).addTo(map).bindTooltip('123', {interactive: true, sticky: true});

    L.polyline([[25.635668, -80.338390],[25.628210, -80.341854],[25.618639, -80.346300],[25.614382, -80.348269]], {color: 'red', weight: 5}).addTo(map).bindTooltip('31, 38', {interactive: true, sticky: true});

    L.polyline([[25.833999, -80.208666],[25.832614, -80.208598]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.447974, -80.476876],[25.447973, -80.478168]], {color: 'red', weight: 5}).addTo(map).bindTooltip('38', {interactive: true, sticky: true});

    L.polyline([[25.955068, -80.120552],[25.948841, -80.120966]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119', {interactive: true, sticky: true});

    L.polyline([[25.800318, -80.127260],[25.796627, -80.128838]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120, 150', {interactive: true, sticky: true});

    L.polyline([[25.800321, -80.126881],[25.806827, -80.124101]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120', {interactive: true, sticky: true});

    L.polyline([[25.771767, -80.149068],[25.775439, -80.140658]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119', {interactive: true, sticky: true});

    L.polyline([[25.895075, -80.124471],[25.888051, -80.123949]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120', {interactive: true, sticky: true});

    L.polyline([[25.834330, -80.208482],[25.835809, -80.208552],[25.837627, -80.208655]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.832554, -80.208397],[25.834330, -80.208482]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.778298, -80.140972],[25.775832, -80.140924]], {color: 'red', weight: 5}).addTo(map).bindTooltip('113, 119, 123', {interactive: true, sticky: true});

    L.polyline([[25.765962, -80.368896],[25.764512, -80.368798]], {color: 'red', weight: 5}).addTo(map).bindTooltip('11', {interactive: true, sticky: true});

    L.polyline([[25.831860, -80.218832],[25.831921, -80.216897]], {color: 'red', weight: 5}).addTo(map).bindTooltip('12, 21, 62', {interactive: true, sticky: true});

    L.polyline([[25.792114, -80.129688],[25.793753, -80.129437]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 112, 113, 119, 150', {interactive: true, sticky: true});

    L.polyline([[25.857337, -80.122478],[25.854799, -80.122382]], {color: 'red', weight: 5}).addTo(map).bindTooltip('119, 120', {interactive: true, sticky: true});

    L.polyline([[25.773366, -80.221701],[25.772937, -80.223536],[25.772801, -80.225090]], {color: 'red', weight: 5}).addTo(map).bindTooltip('11', {interactive: true, sticky: true});

    L.polyline([[25.837627, -80.208655],[25.840055, -80.208770]], {color: 'red', weight: 5}).addTo(map).bindTooltip('77', {interactive: true, sticky: true});

    L.polyline([[25.792069, -80.137457],[25.792113, -80.135967]], {color: 'red', weight: 5}).addTo(map).bindTooltip('113, 119', {interactive: true, sticky: true});

    L.polyline([[25.789278, -80.188188],[25.785022, -80.175926]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 113, 119, 120', {interactive: true, sticky: true});

    L.polyline([[25.774031, -80.191867],[25.777782, -80.191912]], {color: 'red', weight: 5}).addTo(map).bindTooltip('9, 120', {interactive: true, sticky: true});

    L.polyline([[25.812789, -80.122965],[25.814338, -80.122729]], {color: 'red', weight: 5}).addTo(map).bindTooltip('103, 110, 112, 113, 119', {interactive: true, sticky: true});

    L.polyline([[25.815023, -80.123732],[25.812522, -80.124371]], {color: 'red', weight: 5}).addTo(map).bindTooltip('110, 112, 119', {interactive: true, sticky: true});

    L.polyline([[25.888051, -80.123949],[25.883766, -80.123515]], {color: 'red', weight: 5}).addTo(map).bindTooltip('108, 119, 120', {interactive: true, sticky: true});

    L.polyline([[25.792503, -80.129817],[25.790784, -80.131578],[25.792354, -80.132987]], {color: 'red', weight: 5}).addTo(map).bindTooltip('112, 113, 119', {interactive: true, sticky: true});

    L.polyline([[25.796627, -80.128838],[25.792503, -80.129817]], {color: 'red', weight: 5}).addTo(map).bindTooltip('120', {interactive: true, sticky: true});

};

export default initMap;